import { Controller } from "@hotwired/stimulus";
import { debounce } from "../helpers";

const sessionStorage = window.sessionStorage;
const scrollPositionKey = "scrollPosition";

export default class FrameController extends Controller {
  initialize() {
    this.onScroll = debounce(function (e) {
      sessionStorage.setItem(scrollPositionKey, e.target.scrollTop);
    }, 100);
  }

  connect() {
    this.#scrollIfHasScrollPosition();

    this.element.addEventListener("scroll", this.onScroll);
  }

  disconnect() {
    this.element.removeEventListener("scroll", this.onScroll);
  }

  #scrollIfHasScrollPosition() {
    const scrollPosition = sessionStorage.getItem(scrollPositionKey);
    if (null !== scrollPosition) {
      this.element.scrollTo(0, scrollPosition);
    }
  }
}
