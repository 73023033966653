export function debounce(fn, delay = 10) {
  let timeoutId = null;

  return (...args) => {
    const callback = () => fn.apply(this, args);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(callback, delay);
  };
}

export function throttle(fn, delay = 100) {
  let timeoutId = null;

  return (...args) => {
    if (!timeoutId) {
      fn(...args);
      timeoutId = setTimeout(() => (timeoutId = null), delay);
    }
  };
}
